import React from "react"
import copyStyles from "./copy.module.scss"

const Copy = ({ copyDate, copyBody }) => (
    <section className={copyStyles.copy}>
        <div className={copyStyles.copy__block}>
            <p>{copyDate}</p>
            {copyBody}
        </div>
    </section>
)

export default Copy