import React from "react"
import "./awards.scss"

const Awards = ({ awardsHed, awardsItem1, awardsItem2, awardsItem3, awardsItem4, awardsItem5, awardsItem6, awardsItem7, awardsItem8, awardsItem9, awardsItem10 }) => (
    <div className="awards">
        <h4 className="awards__hed">Awards Update</h4>
        <h5 className="awards__subhed">{awardsHed}</h5>
        <ul className="awards__list">
            {
                awardsItem1
                ? <li className="awards__item">{awardsItem1}</li>
                : ""
            }
            {
                awardsItem2
                ? <li className="awards__item">{awardsItem2}</li>
                : ""
            }
            {
                awardsItem3
                ? <li className="awards__item">{awardsItem3}</li>
                : ""
            }
            {
                awardsItem4
                ? <li className="awards__item">{awardsItem4}</li>
                : ""
            }
            {
                awardsItem5
                ? <li className="awards__item">{awardsItem5}</li>
                : ""
            }
            {
                awardsItem6
                ? <li className="awards__item">{awardsItem6}</li>
                : ""
            }
            {
                awardsItem7
                ? <li className="awards__item">{awardsItem7}</li>
                : ""
            }
            {
                awardsItem8
                ? <li className="awards__item">{awardsItem8}</li>
                : ""
            }
            {
                awardsItem9
                ? <li className="awards__item">{awardsItem9}</li>
                : ""
            }
            {
                awardsItem10
                ? <li className="awards__item">{awardsItem10}</li>
                : ""
            }
        </ul>
        
    </div>
)

export default Awards
