import React from "react"
import videoStyles from "./video.module.scss"

const Video = ({ webm, mp4, poster, width, border, pad}) => (
    <video autoPlay loop muted playsInline className={`${videoStyles.video} video--${width} video--${border} video--${pad}`} poster={poster}>
        <source src={webm} type="video/webm"></source>
        <source src={mp4} type="video/mp4"></source>
    </video>
)

export default Video