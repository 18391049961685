import React from "react"
import Img from "gatsby-image"
import heroStyles from "./hero.module.scss"

const Hero = ({ imgData, imgAlt, heroClass }) => (
    <section className={`${heroStyles.hero} ${heroClass}`}>
        <Img
            className={heroStyles.hero__img}
            fluid={imgData}
            alt={imgAlt}
        />
    </section>
)

export default Hero