import React from "react"
import metaStyles from "./meta.module.scss"

export default function Meta({ viewBtnText, viewBtnUrl, rolesHed, yearHed, roleItems, date }) {
    const items = roleItems.map((item) =>
        <li>{item}</li>
    );

    return (
        <div className={metaStyles.meta}>
            <div className={metaStyles.meta__group}>
                <h5 className={metaStyles.meta__hed}>{yearHed}</h5>
                <p className={metaStyles.meta__date}>{date}</p>
            </div>
            <div className={metaStyles.meta__roles}>
                <h5 className={metaStyles.meta__hed}>{rolesHed}</h5>
                <ul className={metaStyles.meta__list}>
                    {items}
                </ul>
            </div>
            {
                (viewBtnUrl 
                    ? <a className={metaStyles.meta__link} href={viewBtnUrl} target="_blank" rel="noopener noreferrer">{viewBtnText} ></a> 
                    : ""
                )
            }
            
        </div>
    )
}