import React from "react"
import gifStyles from "./gif.module.scss"

const Gif = ({ width, number, border, caption, pad, img1Data, img1Alt, img2Data, img2Alt, img3Data, img3Alt, customClass}) => (
    <figure className={`${gifStyles.gif} gif--${width} gif--${number} gif--${border} gif--${pad} ${customClass}`}>
        <img 
            src={img1Data} 
            alt={img1Alt} 
        />
        <img 
            src={img2Data} 
            alt={img2Alt} 
        />
        <img 
            src={img3Data} 
            alt={img3Alt} 
        />
        {(caption ? <figcaption className={gifStyles.gif__caption}>{caption}</figcaption> : '')}
    </figure>
)

export default Gif