import React from "react"
import Img from "gatsby-image"
import figureStyles from "./figure.module.scss"

const Figure = ({ width, test, number, emphasis, border, caption, pad, img1Data, img1Alt, img2Data, img2Alt, img3Data, img3Alt, img4Data, img4Alt, customClass }) => (
    <figure className={`${figureStyles.fig} fig--${width} fig--${number} fig--${emphasis} fig--${border} fig--${pad} ${customClass}`}>
        <Img
            fluid={img1Data}
            alt={img1Alt}
        />
        <Img
            fluid={img2Data}
            alt={img2Alt}
        />
        <Img
            fluid={img3Data}
            alt={img3Alt}
        />
        <Img
            fluid={img4Data}
            alt={img4Alt}
        />
        {(caption ? <figcaption className={figureStyles.fig__caption}>{caption}</figcaption> : '')}
    </figure>
)

export default Figure