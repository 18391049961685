import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Img from "gatsby-image";
import Figure from "../figure/figure"
import Video from "../video/video"
import Gif from "../gif/gif"
import Layout from "../layout/layout"
import Hero from "../hero/hero"
import Meta from "../meta/meta"
import Copy from "../copy/copy"
import Awards from "../awards/awards"
import projectStyles from "./project.module.scss"
import SEO from "../seo.js"

const IMAGE_KEY = "image";

const components = {
  Img,
  Figure,
  Video,
  Gif,
  Awards
}


const Project = ({
  data: {
    mdx: {
      body,
      frontmatter: { title, date, dateRange, embeddedImages, heroImage, heroAlt, heroClass, view, url, role, excerpt },
    },
    site: {
      siteMetadata: { siteTitle, subTitle },
    },
  },
  location
}) => {
  const embeddedImagesByKey =
    embeddedImages &&
    embeddedImages.reduce((images, image, index) => {
      images[`${IMAGE_KEY}${index + 1}`] = images[
        `${IMAGE_KEY}${index + 1}`
      ] || {
        ...image.childImageSharp,
      };
      return images;
    }, {});

    const pageUrl = location.href ? location.href : '';

    return (
      <Layout customClass='layout--project'>

      <SEO 
          title={`${title} | ${siteTitle} | ${subTitle}`}
          description={excerpt}
          ogImage={heroImage.publicURL}
          pageUrl={pageUrl}
      />

      <article className={projectStyles.project}>

      <div className={projectStyles.project__heroGroup}>
        <div className={projectStyles.project__hgroup}>
          <h5 className={projectStyles.project__label}>Project:</h5>
          <h1 className={projectStyles.project__title}>{title}</h1>
          <Meta 
            yearHed={(dateRange ? "Years:" : "Year:")}
            date={(dateRange ? dateRange : date)}
            viewBtnText={view}
            viewBtnUrl={url}
            rolesHed="Roles:"
            roleItems={role}
          />
        </div>
        <Hero 
          heroClass={(heroClass ? heroClass : "")}
          imgData={heroImage.childImageSharp.fluid}
          imgAlt={heroAlt}
        />
        </div>
        <div className={projectStyles.project__body}>
          <Copy
              copyBody={
                <MDXProvider components={components}>
                  <MDXRenderer embeddedImages={embeddedImagesByKey}>{body}</MDXRenderer>
                </MDXProvider>
              }
          />
        </div>
      </article>
    </Layout>
    );
  };

export const query = graphql`
  query BlogQuery($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        dateRange
        date(formatString: "YYYY")
        view
        url
        role
        excerpt
        heroClass
        heroAlt
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
          publicURL
        }
        heroImage2 {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
          publicURL
        }
        embeddedImages {
          childImageSharp {
            original {
              width
              height
              src
            }
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        siteTitle
        subTitle
      }
    }
  }
`

export default Project;